import React, { useEffect, useState } from 'react'

import {
  Box,
  Grid2 as Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import Stack from '@mui/material/Stack'

import Button from 'src/components/Library/Button'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import Modal from 'src/components/Modal/Modal'

import Switch from '../Library/Switch'

interface FormProps {
  open: boolean
  handleClose: () => void
  generateBaserowFormula: (
    name: string,
    description: string,
    prompt: string,
    useAirtableFormula: boolean,
  ) => void
}

const FormulaBuilderGenerateModal: React.FC<FormProps> = ({
  open,
  handleClose,
  generateBaserowFormula,
}) => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const [nameText, setNameText] = useState<string>('')
  const [descriptionText, setDescriptionText] = useState<string>('')
  const [promptText, setPromptText] = useState<string>('')
  const [useExistingFormula, setUseExistingFormula] = useState<boolean>(false)

  const handleGenerate = () => {
    generateBaserowFormula(
      nameText,
      descriptionText,
      promptText,
      useExistingFormula,
    )
  }

  const handleUseExistingFormula = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUseExistingFormula(event.target.checked)
  }

  useEffect(() => {
    setIsGenerating(false)
    setNameText('')
    setDescriptionText('')
    setPromptText('')
    setUseExistingFormula(false)
  }, [open])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      dialogClassName={'!p-0 max-w-[900px]'}
      title={'Generate Formula'}
      disablePortal={false}
      loading={isGenerating}
    >
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        {isGenerating ? (
          <div className="pb-5">
            <div className={'flex h-40 w-full items-center justify-center'}>
              <LoadingSpinner />
            </div>
            <Typography className="animate-pulse text-center text-2xl text-slate-500">
              Generating Your Formula...
            </Typography>
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 12 }}>
              <Stack padding={'20px'}>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid size={{ xs: 4, sm: 4 }}>
                    <InputLabel>Formula Name</InputLabel>
                  </Grid>
                  <Grid size={{ xs: 8, sm: 8 }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={nameText}
                      onChange={(e) => setNameText(e.target.value)}
                    />
                  </Grid>
                  <Grid size={{ xs: 4, sm: 4 }}>
                    <InputLabel>Description</InputLabel>
                  </Grid>
                  <Grid size={{ xs: 8, sm: 8 }}>
                    <TextField
                      fullWidth
                      minRows={2}
                      multiline={true}
                      variant="outlined"
                      value={descriptionText}
                      onChange={(e) => setDescriptionText(e.target.value)}
                    />
                  </Grid>
                  <Grid size={{ xs: 4, sm: 4 }}>
                    <InputLabel>Use Existing Airtable Formula</InputLabel>
                  </Grid>

                  <Grid size={{ xs: 8, sm: 8 }}>
                    <Switch
                      checked={useExistingFormula}
                      onChange={handleUseExistingFormula}
                      name="checkedUseExistingAirtableFormula"
                    />
                  </Grid>

                  <Grid size={{ xs: 4, sm: 4 }}>
                    <InputLabel>
                      {useExistingFormula
                        ? 'Airtable Formula'
                        : 'Free Text Prompt'}
                    </InputLabel>
                  </Grid>
                  <Grid size={{ xs: 8, sm: 8 }}>
                    <TextField
                      fullWidth
                      minRows={4}
                      multiline={true}
                      variant="outlined"
                      value={promptText}
                      onChange={(e) => setPromptText(e.target.value)}
                    />
                  </Grid>

                  <Grid size={{ xs: 8, sm: 8 }}></Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        )}
        {!isGenerating && (
          <div className="float-right py-4">
            <Stack direction={'row'} spacing={3}>
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose()
                }}
                loading={isGenerating}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setIsGenerating(true)
                  handleGenerate()
                }}
                loading={isGenerating}
                buttonDataTestId="generate-new-listing-button"
              >
                Generate
              </Button>
            </Stack>
          </div>
        )}
      </Box>
    </Modal>
  )
}

export default FormulaBuilderGenerateModal
