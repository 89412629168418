import { FC } from 'react'

import {
  CustomFormulasQuery,
  CustomFormulasQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import FormulaBuilderMainSection from 'src/components/FormulaBuilderMainSection/FormulaBuilderMainSection'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { ReloadQuota } from 'src/lib/hooks/Quota/useQuota'

export const QUERY = gql`
  query CustomFormulasQuery {
    customBaserowFormulas {
      id
      createdAt
      updatedAt
      createdByUser {
        id
        name
      }
      approvedByUser {
        id
        name
      }
      status
      formulaData
      name
      description
      approved
      prompt
    }
  }
`

type Props = CellSuccessProps<
  CustomFormulasQuery,
  CustomFormulasQueryVariables
> & { isUsageBlocked: boolean; reloadQuota: ReloadQuota }
export type customFormulasRefetch = Props['queryResult']['refetch']
export type customBaserowFormulas = Props['customBaserowFormulas'][0]

export const Loading = () => <LoadingSpinner />

export const Empty = ({
  customBaserowFormulas,
  isUsageBlocked,
  reloadQuota,
}) => (
  <FormulaBuilderMainSection
    customBaserowFormulas={customBaserowFormulas}
    isUsageBlocked={isUsageBlocked}
    reloadQuota={reloadQuota}
  />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success: FC<Props> = ({
  customBaserowFormulas,
  reloadQuota,
  isUsageBlocked,
}) => {
  return (
    <FormulaBuilderMainSection
      customBaserowFormulas={customBaserowFormulas}
      isUsageBlocked={isUsageBlocked}
      reloadQuota={reloadQuota}
    />
  )
}
