import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import EditorjsCodeflask from '@calumk/editorjs-codeflask'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import EditorJS from '@editorjs/editorjs'
import Embed from '@editorjs/embed'
import Header from '@editorjs/header'
import InlineCode from '@editorjs/inline-code'
import Marker from '@editorjs/marker'
import NestedList from '@editorjs/nested-list'
import Quote from '@editorjs/quote'
import Raw from '@editorjs/raw'
import SimpleImage from '@editorjs/simple-image'
import Table from '@editorjs/table'
import Underline from '@editorjs/underline'
import Warning from '@editorjs/warning'
import { DocumentDuplicateIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  AccordionDetails,
  Box,
  Chip,
  Divider,
  Grid2 as Grid,
  InputLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import Stack from '@mui/material/Stack'
import { EditorCore } from '@react-editor-js/core'
import dayjs from 'dayjs'
import js_beautify from 'js-beautify'
import { createReactEditorJS } from 'react-editor-js'
import Select from 'react-select'
import {
  CustomFormulaQuery,
  updateCustomBaserowFormula,
  updateCustomBaserowFormulaVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  FormulaStatusTypes,
  JSONResultObject,
} from 'src/components/FormulaBuilderMainSection/FormulaBuilderMainSection'
import Accordion from 'src/components/Library/Accordion/Accordion'
import Button from 'src/components/Library/Button'
import { UPDATE_BASEROW_FORMULA } from 'src/lib/queries/CustomFormula/CustomFormula'
import { useAuth } from 'src/Providers'

export interface FormulaBuilderProps {
  formula: CustomFormulaQuery['customBaserowFormula']
  statusTypes: FormulaStatusTypes[]
  setOpenDrawer: Dispatch<SetStateAction<boolean>>
  getColor: (status: string) => string
  getValidJSON: (json: string) => JSONResultObject
}

const FormulaViewer: FC<FormulaBuilderProps> = ({
  formula,
  statusTypes,
  setOpenDrawer,
  getColor,
  getValidJSON,
}) => {
  const { currentUser } = useAuth()

  const [currentStatus, setCurrentStatus] = useState<FormulaStatusTypes>(null)
  const [changingStatus, setChangingStatus] = useState<boolean>(false)
  const [approvedDateFormatted, setApprovedDateFormatted] =
    useState<string>(null)
  const [approvedBy, setApprovedBy] = useState<string>(null)
  const [formulaJSON, setFormulaJSON] = useState<any>(null)
  const editorCore = useRef<EditorCore>(null)
  const [ready, setReady] = useState(false)
  const ReactEditorJS = createReactEditorJS()
  const getEditor = () =>
    editorCore.current.dangerouslyLowLevelInstance as EditorJS

  const createdAtDate = new Date(formula?.createdAt)
  const createdDateFormatted = formula?.createdAt
    ? dayjs(createdAtDate).format('DD/MM/YYYY')
    : null

  const [updateCustomBaserowFormula] = useMutation<
    updateCustomBaserowFormula,
    updateCustomBaserowFormulaVariables
  >(UPDATE_BASEROW_FORMULA, {
    onCompleted: () => {
      toast.success('Updated', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['CustomFormulasQuery', 'CustomFormulaQuery'],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  const handleInitialize = useCallback((instance: EditorCore) => {
    editorCore.current = instance

    setReady(true)
  }, [])

  const handleReady = () => {
    const editor = getEditor()
    editor.readOnly.toggle(true)
  }

  const handleCopyDataToClipboard = () => {
    const data = getDocDetails()

    navigator.clipboard.writeText(data.blocks[0].data.code)
    toast.success('Copied', {
      duration: 2000,
      className: 'flex-column',
    })
  }

  const handleChangeStatus = async (value: any) => {
    setChangingStatus(true)

    await updateCustomBaserowFormula({
      variables: {
        id: formula.id,
        input: {
          status: value.value,
          approvedBy:
            value.value === 'APPROVED' ? currentUser.userData.id : null,
          approved:
            value.value === 'APPROVED' ? new Date().toISOString() : null,
        },
      },
    })
    setChangingStatus(false)
  }

  useEffect(() => {
    // Find the status object in the statusTypes array that matches formula.status
    const matchingStatus = statusTypes.find(
      (status) => status.value === formula?.status,
    )

    // Set the currentStatus state to the found status object
    setCurrentStatus(matchingStatus || null)

    const approvedDate = new Date(formula?.approved)
    setApprovedDateFormatted(
      formula?.approved ? dayjs(approvedDate).format('DD/MM/YYYY') : null,
    )
    setApprovedBy(formula?.approvedByUser?.name)
  }, [formula])

  useEffect(() => {
    const formulaJSONResult = getValidJSON(formula?.formulaData)
    setFormulaJSON(formulaJSONResult)
  }, [formula])

  const getDocDetails = useCallback(() => {
    const formulaJSONResult = getValidJSON(formula?.formulaData)
    const formattedFormula = js_beautify(
      formulaJSONResult.formula.toLowerCase(),
      {
        indent_size: 2,
        // space_in_empty_paren: true,
      },
    )
    return {
      time: 1701930653481,
      blocks: [
        {
          id: 'formulaSection',
          data: {
            code: formattedFormula,
            language: 'javascript',
            showlinenumbers: true,
          },
          type: 'code',
        },
      ],
      version: '2.28.2',
    }
  }, [formula])

  return (
    <Box className="p-6">
      <Stack
        direction="row"
        alignItems="right"
        justifyContent="flex-end"
        spacing={2}
        width={'100%'}
      >
        <XMarkIcon
          onClick={() => {
            setOpenDrawer(false)
          }}
          className={'h-6 w-6 text-gray-500 hover:cursor-pointer'}
        />
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        className={'flex w-full flex-row justify-between p-5'}
      >
        <Grid container spacing={2}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid size={{ xs: 12, sm: 12 }}>
              <Stack direction={'row'} spacing={1} alignItems="center">
                <Typography style={{ fontSize: '2rem' }}>
                  {formula.name}
                </Typography>
                <Chip
                  label={formulaJSON?.confidence + '%'}
                  className={getColor(formulaJSON?.confidence)}
                ></Chip>
              </Stack>
            </Grid>
            <Grid size={{ xs: 12, sm: 12 }}>
              <Stack direction={'row'} spacing={2} alignItems="center">
                <Typography
                  className={'text-gray-400'}
                  style={{ fontSize: '0.7rem' }}
                >
                  Created {createdDateFormatted} By{' '}
                  {formula?.createdByUser?.name}
                </Typography>
                {approvedDateFormatted && (
                  <Typography
                    className={'text-gray-400'}
                    style={{ fontSize: '0.7rem' }}
                  >
                    Approved {approvedDateFormatted} By{' '}
                    {formula?.approvedByUser?.name}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid size={{ xs: 12, sm: 12 }} className={'mt-6'}>
              <Typography>
                <b>Description:</b>
                <br /> {formula.description}
              </Typography>
            </Grid>

            <Grid size={{ xs: 12, sm: 12 }}>
              <Typography>
                <b>Original Prompt: </b>
                <br />
                {formula.prompt}
              </Typography>
            </Grid>
            {formulaJSON?.error && (
              <Grid size={{ xs: 12, sm: 12 }} className="mt-4">
                <Typography>
                  <div className="rounded bg-red-100 px-4 py-2 text-red-600">
                    <span>Potential issues: </span>
                    <span>{formulaJSON?.error || 'None'}</span>
                  </div>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Stack spacing={2} className="flex-shrink-0">
          <Select
            className="min-w-[200px]"
            value={currentStatus}
            isLoading={changingStatus}
            placeholder="Status"
            onChange={handleChangeStatus}
            options={statusTypes}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                borderRadius: '5px',
                height: '40px',
                paddingLeft: '15px',
              }),
            }}
          />
        </Stack>
      </Stack>

      <div className="mt-4">
        <Divider className="my-4" />
        <Stack
          direction={'row'}
          spacing={1}
          sx={{ marginTop: 2, marginBottom: 2, alignItems: 'center' }}
        >
          <InputLabel sx={{ fontSize: '12px' }}>FORMULA</InputLabel>

          <Tooltip title={'Generated Formula'}>
            <div>
              <Button
                fullWidth={false}
                variant="outlined"
                className="min-w-[0] p-1"
                onClick={handleCopyDataToClipboard}
              >
                <DocumentDuplicateIcon className="h-3 w-3" />
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </div>
      <div id="fullWidthDoc">
        <ReactEditorJS
          holder={'editorjs'}
          onInitialize={handleInitialize}
          onReady={handleReady}
          tools={{
            embed: Embed,
            table: Table,
            list: NestedList,
            warning: Warning,
            raw: Raw,
            header: Header,
            quote: Quote,
            marker: Marker,
            checklist: CheckList,
            delimiter: Delimiter,
            inlineCode: InlineCode,
            simpleImage: SimpleImage,
            underline: Underline,
            code: EditorjsCodeflask,
          }}
          defaultValue={getDocDetails()}
        />
        <Accordion title={'Explanation:'}>
          <AccordionDetails>
            <Typography>{formulaJSON?.description || 'None'}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </Box>
  )
}

export default FormulaViewer
