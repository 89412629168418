import React, { FC, useEffect, useState } from 'react'

import { SparklesIcon } from '@heroicons/react/24/solid'
import { Chip, Drawer, InputLabel, Grid2 as Grid } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import Select from 'react-select'
import { CustomFormulasQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import FormulaBuilderGenerateModal from 'src/components/FormulaBuilderGenerateModal/FormulaBuilderGenerateModal'
import FormulaViewerCell from 'src/components/FormulaViewerCell'
import Button from 'src/components/Library/Button/Button'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { GENERATE_CUSTOM_FORMULA } from 'src/lib/queries/CustomFormula/CustomFormula'

export interface PropertyGenieMainPageProps {
  customBaserowFormulas: CustomFormulasQuery['customBaserowFormulas']
  isUsageBlocked: boolean
  reloadQuota: () => void
}

interface SortTypes {
  value: string
  label: string
}

export type JSONResultObject = {
  error: string
  formula: string
  confidence: number
  description: string
}

export type FormulaStatusTypes = {
  value: string
  label: string
  color: string
}

export type themeType = {
  id: number
  label: string
}

const statusTypes: FormulaStatusTypes[] = [
  { value: 'PENDING', label: 'Pending', color: '#FF9800' }, // Yellow
  { value: 'APPROVED', label: 'Approved', color: '#4CAF50' }, // Green
  { value: 'REJECTED', label: 'Rejected', color: '#F44336' }, // Red
  { value: 'ARCHIVED', label: 'Archived', color: '#9E9E9E' }, // Grey
]

const sortTypes: SortTypes[] = [
  { value: '0', label: 'Name: A-Z' },
  { value: '1', label: 'Name: Z-A' },
  { value: '2', label: 'Created Date' },
  { value: '3', label: 'Created By' },
  { value: '4', label: 'Status' },
]

const FormulaBuilderMainPage: FC<PropertyGenieMainPageProps> = ({
  customBaserowFormulas,
  isUsageBlocked,
  reloadQuota,
}) => {
  const [openCreateFormulaModal, setOpenCreateFormulaModal] =
    useState<boolean>(false)
  const [filteredFormulas, setFilteredFormulas] = useState([])
  const [filteredFormulasReduced, setFilteredFormulasReduced] = useState([])
  const [sortedPropertyContents, setSortedPropertyContents] = useState([])
  const [selectedContent, setSelectedContent] = useState(null)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [filterValue, setFilterValue] = useState<FormulaStatusTypes[]>([])
  const [sortValue, setSortValue] = useState<SortTypes>(null)
  const { trackEvent } = useAnalytics()

  const [generateCustomBaserowFormula] = useMutation(GENERATE_CUSTOM_FORMULA, {
    onCompleted: () => {
      handleClose()
      reloadQuota()
    },
    refetchQueries: ['CustomFormulasQuery'],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message, {
        duration: 2000,
        className: 'flex-column',
      })
    },
  })

  function getColor(value) {
    if (value > 90) {
      return 'bg-green-100 text-green-600'
    } else if (value > 50) {
      return 'bg-orange-100 text-orange-600'
    } else {
      return 'bg-red-100 text-red-600'
    }
  }

  const handleCreateFormula = async (
    nameText,
    descriptionText,
    promptText,
    useAirtableFormula,
  ) => {
    await generateCustomBaserowFormula({
      variables: {
        name: nameText,
        description: descriptionText,
        prompt: promptText,
        useAirtableFormula: useAirtableFormula,
      },
    })
  }

  const handleClose = () => {
    setOpenCreateFormulaModal(false)
    trackEvent('Formula generator', 'close formula generate modal')
  }

  const toTitleCase = (str) => {
    if (typeof str !== 'string') {
      return ''
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const hexToRgba = (hex, alpha) => {
    const [r, g, b] = [
      parseInt(hex.slice(1, 3), 16),
      parseInt(hex.slice(3, 5), 16),
      parseInt(hex.slice(5, 7), 16),
    ]
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  const getStatusColor = (status) => {
    const statusItem = statusTypes.find((item) => item.value === status)
    return statusItem ? statusItem.color : '#FFFFFF' // Default to white if not found
  }

  const SquareDot = ({ color }) => (
    <div
      style={{
        width: '5px',
        height: '5px',
        backgroundColor: color,
        marginLeft: '8px',
      }}
    />
  )

  const sortProperties = (properties) => {
    const copiedProperties = [...properties]
    return copiedProperties.sort((a, b) => {
      const aLatestAd = a || {}
      const bLatestAd = b || {}

      switch (sortValue.value) {
        case '0': {
          const aAddress = a.sourceData.name || ''
          const bAddress = b.sourceData.name || ''
          if (!aAddress && bAddress) return 1
          if (aAddress && !bAddress) return -1
          return aAddress.localeCompare(bAddress)
        }

        case '1': {
          const aAddress = a.sourceData.name || ''
          const bAddress = b.sourceData.name || ''
          if (!aAddress && bAddress) return -1
          if (aAddress && !bAddress) return 1
          return bAddress.localeCompare(aAddress)
        }

        case '2': {
          const aDate = aLatestAd?.createdAt || ''
          const bDate = bLatestAd?.createdAt || ''
          if (!aDate && bDate) return 1
          if (aDate && !bDate) return -1
          return aDate < bDate ? 1 : aDate > bDate ? -1 : 0
        }

        case '3': {
          const aName = String(aLatestAd?.createdByUser?.name || '')
          const bName = String(bLatestAd?.createdByUser?.name || '')
          if (!aName && bName) return 1
          if (aName && !bName) return -1
          return aName.localeCompare(bName)
        }

        case '4': {
          const aStatus = aLatestAd?.status || ''
          const bStatus = bLatestAd?.status || ''
          if (!aStatus && bStatus) return 1
          if (aStatus && !bStatus) return -1
          return aStatus.localeCompare(bStatus)
        }

        default: {
          return 0
        }
      }
    })
  }

  const handleSelectedChange = (id) => {
    const selectedFormulaSearch = customBaserowFormulas.find(
      (formula) => formula.id === id,
    )
    setSelectedContent(selectedFormulaSearch)
  }

  const handleFilter = (value: FormulaStatusTypes[]) => {
    setFilterValue(value)
    trackEvent('Formula generator', 'filter by status', {
      status: value.map((item) => item.value).join(','),
    })
  }

  const handleSort = (value: SortTypes) => {
    setSortValue(value)
    trackEvent('Formula generator', 'sort by', {
      sortBy: value.label,
    })
  }

  const getValidJSON = (jsonString: string): JSONResultObject => {
    let convertedJSON: JSONResultObject

    if (jsonString.includes('```json')) {
      const jsonPart = jsonString.split('```json')[1].split('```')[0]
      const explainPart = jsonString
        .split('```json')[0]
        .replace('Here is the JSON', '')
        .trim()

      convertedJSON = JSON.parse(jsonPart)
      convertedJSON.error = explainPart + '\n\n' + (convertedJSON.error || '')
    } else {
      try {
        convertedJSON = JSON.parse(jsonString)
      } catch (e) {
        convertedJSON = {
          error: e.message,
          formula: jsonString,
          confidence: 0,
          description: '',
        }
      }
    }

    return convertedJSON
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'FORMULA NAME',
      minWidth: 500,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => <span>{params.row?.name}</span>,
    },
    {
      field: 'created',
      sortable: false,
      filterable: false,
      headerName: 'CREATED',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.createdAt)
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}/${date.getFullYear()}`
        return <span>{formattedDate}</span>
      },
    },
    {
      field: 'createdBy',
      sortable: false,
      filterable: false,
      headerName: 'CREATED BY',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return <span>{params.row?.createdByUser?.name}</span>
      },
    },
    {
      field: 'STATUS',
      sortable: false,
      filterable: false,
      headerName: 'STATUS',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={toTitleCase(params.row.status)}
            size="small"
            icon={<SquareDot color={getStatusColor(params.row.status)} />}
            style={{
              backgroundColor: hexToRgba(
                getStatusColor(params.row.status),
                0.2,
              ),
              color: getStatusColor(params.row.status),
              borderRadius: '5px',
            }}
          />
        )
      },
    },
    {
      field: 'confidence',
      sortable: false,
      filterable: false,
      headerName: 'CONFIDENCE',
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        const results = getValidJSON(params.row?.formulaData)
        return (
          <Chip
            label={results.confidence + '%'}
            size="small"
            className={getColor(results.confidence)}
            style={{
              borderRadius: '5px',
            }}
          />
        )
      },
    },
  ]

  useEffect(() => {
    if (filterValue.length > 0) {
      const filteredData = filteredFormulasReduced.filter((property) => {
        return filterValue.some((status) => {
          return property.status === status.value
        })
      })
      setFilteredFormulas(filteredData)
    } else {
      setFilteredFormulas(filteredFormulasReduced)
    }
  }, [filteredFormulasReduced, filterValue])

  useEffect(() => {
    if (customBaserowFormulas?.length === 0) return

    setFilteredFormulasReduced(customBaserowFormulas)
  }, [customBaserowFormulas])

  useEffect(() => {
    if (!sortValue) {
      setSortedPropertyContents(filteredFormulas)
    } else {
      const sortedProperties = sortProperties([...filteredFormulas])
      setSortedPropertyContents(sortedProperties)
    }
  }, [sortValue, filteredFormulas])

  return (
    <div className={'relative flex h-full w-full flex-row'}>
      <div className="h-full w-full justify-center overflow-y-auto p-5">
        <Grid container spacing={2} className="my-2">
          <Grid size={{ xs: 3 }}></Grid>
          <Grid size={{ xs: 1 }}></Grid>

          <Grid
            size={{ xs: 1 }}
            style={{
              alignItems: 'center',
              justifyContent: 'right',
              display: 'flex',
            }}
          >
            <InputLabel htmlFor="filter-select">SORT BY</InputLabel>
          </Grid>

          <Grid size={{ xs: 2 }}>
            <Select
              className="w-full"
              value={sortValue}
              onChange={handleSort}
              placeholder="Default"
              options={sortTypes}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  height: '40px',
                  paddingLeft: '15px',
                }),
              }}
            />
          </Grid>

          <Grid
            size={{ xs: 1 }}
            style={{
              alignItems: 'center',
              justifyContent: 'right',
              display: 'flex',
            }}
          >
            <InputLabel htmlFor="filter-select">FILTER BY</InputLabel>
          </Grid>

          <Grid size={{ xs: 2 }}>
            <Select
              className="w-full"
              value={filterValue}
              isMulti
              placeholder="Status"
              onChange={handleFilter}
              options={statusTypes}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  height: '40px',
                  overflowY: 'auto',
                }),
              }}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Button
              startIcon={<SparklesIcon className="h-5 w-5" />}
              className="min-w-[0] rounded-lg"
              disabled={isUsageBlocked}
              onClick={() => {
                setOpenCreateFormulaModal(true)
                trackEvent('Ad Generator', 'click create ad')
              }}
            >
              Create New Formula
            </Button>
          </Grid>
        </Grid>
        <div className={'rounded-md bg-white'}>
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              zIndex: 0,
            }}
            autoHeight
            rows={sortedPropertyContents}
            columns={columns}
            onRowClick={(params) => {
              handleSelectedChange(params.row.id)
              setOpenDrawer(true)
              trackEvent('Ad Generator', 'click property', {
                propertyId: params.row.id,
              })
            }}
            pageSizeOptions={[10]}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            onPaginationModelChange={(params) => {
              trackEvent('Ad Generator', 'paginate through properties', {
                pageIndex: params.page,
              })
            }}
          />
        </div>
      </div>

      <div>
        <Drawer
          open={openDrawer}
          anchor="right"
          onClose={() => setOpenDrawer(false)}
          sx={{ width: '50%', flexShrink: 0 }}
          PaperProps={{ sx: { width: '50%' } }}
        >
          {openDrawer && (
            <FormulaViewerCell
              id={selectedContent.id}
              statusTypes={statusTypes}
              setOpenDrawer={setOpenDrawer}
              getColor={getColor}
              getValidJSON={getValidJSON}
            />
          )}
        </Drawer>

        <FormulaBuilderGenerateModal
          open={openCreateFormulaModal}
          handleClose={handleClose}
          generateBaserowFormula={handleCreateFormula}
        />
      </div>
    </div>
  )
}

export default FormulaBuilderMainPage
