import React, { Dispatch, FC, SetStateAction } from 'react'

import { CustomFormulaQuery, CustomFormulaQueryVariables } from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import {
  FormulaStatusTypes,
  JSONResultObject,
} from 'src/components/FormulaBuilderMainSection/FormulaBuilderMainSection'
import FormulaViewer from 'src/components/FormulaViewer/FormulaViewer'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query CustomFormulaQuery($id: Int!) {
    customBaserowFormula(id: $id) {
      id
      createdAt
      updatedAt
      createdByUser {
        id
        name
      }
      approvedByUser {
        id
        name
      }
      status
      formulaData
      name
      description
      approved
      prompt
    }
  }
`

type Props = CellSuccessProps<
  CustomFormulaQuery,
  CustomFormulaQueryVariables
> & {
  statusTypes: FormulaStatusTypes[]
  setOpenDrawer: Dispatch<SetStateAction<boolean>>
  getColor: (status: string) => string
  getValidJSON: (json: string) => JSONResultObject
}
export type customFormulaRefetch = Props['queryResult']['refetch']
export type customBaserowFormula = Props['customBaserowFormula']

export const Loading = () => <LoadingSpinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success: FC<Props> = ({
  customBaserowFormula,
  statusTypes,
  setOpenDrawer,
  getColor,
  getValidJSON,
}) => {
  return (
    <FormulaViewer
      formula={customBaserowFormula}
      statusTypes={statusTypes}
      setOpenDrawer={setOpenDrawer}
      getColor={getColor}
      getValidJSON={getValidJSON}
    />
  )
}
