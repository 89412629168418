import * as React from 'react'

import { Grid2 as Grid } from '@mui/material'
import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import FormulaBuilderCell from 'src/components/FormulaBuilderCell'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import useQuota from 'src/lib/hooks/Quota/useQuota'

const FormulaBuilderPage = () => {
  const { QuotaComponent, isUsageBlocked, reloadQuota } = useQuota({
    feature: FLAG_FEATURES.CUSTOM_BASEROW_FORMULA,
  })

  return (
    <>
      <Metadata
        title="Baserow Formula Generator"
        description="Baserow Formula Generator"
      />
      <div className={'flex h-screen flex-col'}>
        <PageHeader title={'Baserow Formula Generator'} />
        <FeatureToggle
          feature={FLAG_FEATURES.CUSTOM_BASEROW_FORMULA}
          InactiveComponent={<FeatureDenied hubspotFormId={null} />}
        >
          <div className="flex h-full flex-col overflow-y-auto">
            <Grid
              className={'flex items-center border-b bg-white px-5 pt-2.5'}
              container
              spacing={2}
            >
              <Grid size={{ xs: 6 }}></Grid>
              <Grid size={{ xs: 6 }}>
                <QuotaComponent />
              </Grid>
            </Grid>
            <div className="flex-grow overflow-hidden">
              <FormulaBuilderCell
                isUsageBlocked={isUsageBlocked}
                reloadQuota={reloadQuota}
              />
            </div>
            <div id="map"></div>
          </div>
        </FeatureToggle>
      </div>
    </>
  )
}

export default FormulaBuilderPage
