export const GENERATE_CUSTOM_FORMULA = gql`
  mutation generateCustomBaserowFormula(
    $name: String!
    $description: String!
    $prompt: String!
    $useAirtableFormula: Boolean!
  ) {
    generateCustomBaserowFormula(
      prompt: $prompt
      name: $name
      description: $description
      useAirtableFormula: $useAirtableFormula
    ) {
      id
    }
  }
`
export const UPDATE_BASEROW_FORMULA = gql`
  mutation updateCustomBaserowFormula(
    $id: Int!
    $input: UpdateCustomBaserowFormulaInput!
  ) {
    updateCustomBaserowFormula(id: $id, input: $input) {
      id
    }
  }
`
